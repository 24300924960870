import React from "react";
import '../index.css';
import ScrollToTopOnMount from "../components/ScrollToTopOnMount"


function NoMatch () {
    
        return (
            <>
            <ScrollToTopOnMount />
                
                
            </>)
    }


export default NoMatch;
